import { Button } from "~/components/Button";
import { cn } from "~/utils/cn";
import { useBoardConfig } from "../contexts/BoardConfigContext";
import { Bell, CopyPlus } from "lucide-react";
import { NewCountBadge } from "~/components/NewCountBadge";

export const BoardActions: React.FC<{
  board: { _id: string; name: string; newAlertsCount: number };
}> = ({ board }) => {
  const { config, setAddColumn, setShowAlerts } = useBoardConfig(board._id);
  return (
    <div className="hidden md:block p-2">
      <div className="grid grid-cols-2 gap-2 relative sm:grid-cols-2 md:grid-cols-1 2xl:grid-cols-2">
        <Button
          className={cn(
            "flex flex-col gap-2 items-center text-xs font-normal py-2 rounded-xl sm:px-1 md:px-2 2xl:px-1 relative",
            config.showAlerts && "ring-1 ring-gray-400"
          )}
          variant={"transparent"}
          onClick={() => setShowAlerts(!config.showAlerts)}
        >
          <Bell className="w-5 h-5 text-white" />
          <div className="sm:block md:hidden 2xl:block">Alerts</div>
          <div
            className={cn(
              "absolute top-1",
              board.newAlertsCount > 99
                ? "right-0"
                : board.newAlertsCount > 9
                ? "right-1.5"
                : "right-3"
            )}
          >
            <NewCountBadge count={board.newAlertsCount} />
          </div>
        </Button>
        <Button
          className={cn(
            "flex flex-col gap-2 items-center text-xs font-normal py-2 rounded-xl sm:px-1 md:px-2 2xl:px-1",
            config.showAddColumn && "ring-1 ring-gray-400"
          )}
          variant={"transparent"}
          onClick={() => setAddColumn(!config.showAddColumn)}
        >
          <CopyPlus className="text-white w-5 h-5" />
          <div className="sm:block md:hidden 2xl:block">Add Column</div>
        </Button>
      </div>
    </div>
  );
};
