import { Link } from "@remix-run/react";
import { CircleUserIcon } from "lucide-react";

export const Account = ({
  email,
  setMenuOpen,
}: {
  email: string;
  setMenuOpen?: (open: boolean) => void;
}) => {
  return (
    <div className="p-2">
      <Link
        to="/boards/settings"
        className="inline-block md:w-full outline-none bg-primary-background md:bg-primary-foreground hover:bg-primary-background  cursor-pointer gap-2 items-center p-4 md:p-2 rounded-2xl md:rounded-xl"
        onClick={() => {
          setMenuOpen ? setMenuOpen(false) : "";
        }}
      >
        {/* <img className="min-w-[32px] h-8 rounded-full bg-tertiary-accent" alt=""></img> */}
        <div className="text-xs text-secondary sm:block md:hidden 2xl:block lg:my-1">{email}</div>
        <CircleUserIcon className="w-5 h-5 text-white hidden sm:hidden md:block 2xl:hidden" />
      </Link>
    </div>
  );
};
